<template>
    <div>
      <div class="ditchbox">
        <el-card class="management">
          <div class="finish_warp" v-for="(item, index) in tem_list" :key="index">
            <div class="finish_nav">
              <img
                :src="item.cover_img_url?item.cover_img_url:'https://vipuser3.eweishop.com//static/dist/shop/image/pc/template1/preview.png'"
                alt=""
              />
                  <!-- <iframe :src="'../fitment?id='+item.id" frameborder="0"></iframe> -->
              <div
                class="img-active d-flex align-items-center justify-content-center"
                :class="[item.is_index == 1 ? 'active' : '']"
                @click.stop="adhibition(item, index)" v-if="hasPerm(['pc.diy.update'])"
              >
                <div
                  class="img-text d-flex align-items-center justify-content-center"
                >
                  {{ item.is_index == 1 ? "已应用" : "应用" }}
                </div>
              </div>
            </div>
            <div class="finish_foot">
              <h3 class="show-two-lines">{{ item.name }}</h3>
              <p class="show-one-lines">{{ item.created_at }}</p>
  
              <div class="info-active d-flex">
                <div
                  class="info_edit d-flex align-items-center justify-content-end"
                  @click.stop="editFinsh(item)"
                >
                  <div class="info_con">
                    <i class="el-icon-edit-outline"></i>
                    <p>编辑</p>
                  </div>
                </div>
                <div
                  class="info_del d-flex align-items-center"
                  @click.stop="delFinsh(item, index)"
                >
                  <div class="info_con">
                    <i class="el-icon-delete"></i>
                    <p>删除</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="finish_warp d-flex justify-content-center align-items-center"
            @click.stop="appendFin"
          >
            <div class="finishAdd">
              <div
                class="add_icon d-flex justify-content-center align-items-center"
              >
                +
              </div>
              <p>添加模板</p>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        pages: 1,
        limit: 10,
        tem_list: [],
        mobileActive:this.$route.query.active_id||this.$route.query.activity_id||'0',
      };
    },
    created() {
      this.getfitment();
    },
    methods: {
      getfitment() {
        let that = this;
        let data = {
          search:[],
          order:[],
        };
        this.$get(this.$apis.mobileFinishList+this.mobileActive, data)
          .then((res) => {
            that.tem_list = res.data.list;
          })
          .catch((err) => {
            that.common.message(that, err.message);
          });
      },
      adhibition(item, index) {
        let that = this;
        let api = this.$apis.mobileFinishHome + item.page_id + "/set_index";
        this.$put(api)
          .then((res) => {
            if (res.code == 200) {
              that.$message({
                type: "success",
                message: "更新成功",
                showClose: true,
                offset: 200,
                duration: 1000,
              });
              that.tem_list.forEach((i, b) => {
                i.is_index = 0;
                if (index == b) {
                  i.is_index = i.is_index == 1 ? 0 : 1;
                }
              });
            } else {
              that.$message({
                type: "warning",
                message: "" + res.message,
                showClose: true,
                offset: 200,
                duration: 1000,
              });
            }
          })
          .catch((err) => {
            that.common.message(that, err.message);
          });
      },
      // 编辑
      editFinsh(item) {
        const routerPath = this.$router.resolve({
          path: "/client/mobile/fitment",
          query: {
            id: item.id,
            active_id:this.mobileActive,
          },
        });
        window.open(routerPath.href, "_blank");
      },
      delFinsh(item, index) {
        let that = this;
        this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.submitDel(item, index);
          })
          .catch(() => {});
      },
      submitDel(item, index) {
        let that = this;
        this.$delete(this.$apis.mobileFinishDel + item.id)
          .then((res) => {
            if (res.code == 200) {
              that.$message({
                type: "success",
                message: "删除成功",
                showClose: true,
                offset: 200,
                duration: 1000,
              });
              that.tem_list.splice(index, 1);
            } else {
              that.$message({
                type: "warning",
                message: "" + res.message,
                showClose: true,
                offset: 200,
                duration: 1000,
              });
            }
          })
          .catch((err) => {
            that.common.message(that, err.message);
          });
      },
      appendFin() {
        var routerPaths = this.$router.resolve({
          path: "/client/mobile/fitment",
          query:{
            active_id:this.mobileActive
          }
        });
        window.open(routerPaths.href, "_blank");
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .ditchbox {
    width: 100%;
    // padding: 20px;
    border-radius: 4px;
    box-sizing: border-box;
    // background: #fff;
    display: flex;
    flex-wrap: wrap;
  }
  .management {
    width: 100%;
    
    /deep/.el-card__body{
      display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    }
  }
  
  /* 首页装修 */
  .finish_warp {
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    // width: 600px;
    // height: 416px;
    border: 1px solid #d8e4ff;
    margin: 20px;
    width: 275px;
    height: 452px;
    cursor: pointer;
    &:hover {
      .info-active {
        visibility: visible;
        opacity: 1;
      }
      .img-active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .finish_head {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom: 1px solid #d8e4ff;
    background: #fbfcff;
    // height: 68px;
    line-height: 66px;
    padding: 0 20px;
    font-size: 16px;
    height: 84px;
    padding: 20px;
    position: relative;
  }
  .finish_foot {
    // height: 84px;
    padding: 20px;
    position: relative;
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      margin: 0;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      margin: 4px 0 0;
    }
    .info-active {
      position: absolute;
      width: 100%;
      height: calc(100% - 2px);
      top: 0;
      left: 0;
      background: #fff;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s;
      .info_edit {
        text-align: right;
        padding-right: 30px;
        width: 50%;
        cursor: pointer;
        .info_con {
          text-align: center;
          i {
            font-size: 16px;
            margin: 0 auto;
          }
          p {
            margin: 6px 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            transition: color 0.3s;
            line-height: 18px;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
          }
          &:hover {
            i {
              color: #4877fb;
            }
            p {
              color: #4877fb;
            }
          }
        }
      }
      .info_del {
        padding-left: 30px;
        width: 50%;
        cursor: pointer;
        .info_con {
          text-align: center;
          i {
            font-size: 16px;
            margin: 0 auto;
          }
          p {
            margin: 6px 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            transition: color 0.3s;
            line-height: 18px;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
          }
          &:hover {
            i {
              color: #4877fb;
            }
            p {
              color: #4877fb;
            }
          }
        }
      }
    }
  }
  
  .img-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    cursor: pointer;
    z-index: 500;
    .img-text {
      width: 60px;
      height: 60px;
      background: #4877fb;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }
  }
  .img-active.active {
    visibility: visible;
    opacity: 1;
  }
  .finish_head a {
    float: right;
    margin-top: 16px;
  }
  .finish_nav {
    padding: 20px;
    height: 328px;
    position: relative;
    overflow: hidden;
  }
  .finish_nav img {
    width: 100%;
    height: auto;
  }
  .finishAdd {
    .add_icon {
      background: #4877fb;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      color: #fff;
      font-size: 38px;
      font-weight: 100;
    }
    p {
      margin: 16px 0 0;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      text-align: center;
    }
  }
  .ditchfoot {
    position: fixed;
    left: 240px;
    right: 0;
    bottom: 0;
    min-width: 980px;
    margin: 0 24px;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 100;
    background: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    .management_foot_body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  }
  </style>